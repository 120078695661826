import { FaBars } from "react-icons/fa";
import Component from "../../../components/components";
import { useContext, useEffect, useState } from "react";
import Category from "../../../types/category.type";
import { useTheme } from "../../../context/theme.context";
import { CategoryContext } from "../../../context/category.provider";
import { Link } from "react-router-dom";
import './index.css'; // Importando o arquivo CSS

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
};
const SideBarComponentHeader: React.FC = ({  }) => {
  const [width] = useWindowSize();
  const { currentTheme } = useTheme();

  const { categories, selectCategory, selectSubCategory } = useContext(CategoryContext);
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null);


  // Manipula o clique na categoria para dispositivos móveis e desktops
  const handleCategoryClick = (e: React.MouseEvent<HTMLAnchorElement>, category: Category) => {
    if (window.innerWidth <= 880) {
      e.preventDefault(); // Previne a navegação em dispositivos móveis
      setActiveSubmenu(activeSubmenu === category.id ? null : category.id);
    } else {
      selectCategory(category);
      selectSubCategory(null);
    }
  };
  const handleSubCategoryClick = (category: any, subcategory: any) => {
    selectCategory(category);
    selectSubCategory(subcategory);
  };

  // Estado para mostrar/ocultar categorias extras
  const [showExtraCategories, setShowExtraCategories] = useState(false);

  // Suponha que cada item do menu tenha uma largura média de 160 pixels
  const menuItemWidth = 160;
  const maxItems = Math.floor(width / menuItemWidth);
  const displayedCategories = categories.slice(0, maxItems);
  const extraCategories = categories.slice(maxItems);
  const hasExtraCategories = categories.length > maxItems;



  return (
    <div className={`navigation-header`} style={{ backgroundColor: currentTheme.color.primary }} >
      <nav  >
        <ul className="ulheader" >
          {displayedCategories.map(category => (
            <li key={category.id} className="menu-item liheader"
              onMouseEnter={ () => setActiveSubmenu(category.id) }
              onMouseLeave={ () => setActiveSubmenu(null) }
            >
              <Link
                to="/produtos"
                onClick={(e) => handleCategoryClick(e, category)}
                style={{ color: 'white' }}
              >
                {category.descricao}
              </Link>
              {activeSubmenu === category.id && category.subCategorias &&
                <ul className="submenu ulheader">
                  {category.subCategorias.map(subcategory => (
                    <li key={subcategory.id}  className="liheader" >
                      <Link
                        to="/produtos"
                        onClick={() => handleSubCategoryClick(category, subcategory)}
                        className="submenu-li"
                      >
                        <Component.Shared.TextComponent className='text-submenu'  margin={0} value={subcategory.descricao} color={ 'grey'} size={13} align="flex-start" />
                      </Link>
                    </li>
                  ))}
                </ul>
              }
            </li>

          ))}
          {hasExtraCategories && (
            <li className={`menu-item liheader`}
              onMouseEnter={() => setShowExtraCategories(true)}
              onMouseLeave={() => setShowExtraCategories(false)}>
              <div className="hamburger-icon2">
                <FaBars color={currentTheme.color.secondary} size={32} />
              </div>
              {showExtraCategories && (
                <ul className="submenu ulheader">
                  {extraCategories.map(category => (
                    <li key={category.id} className="liheader">
                      <Link to="/produtos" state={{ category: category }} onClick={(e) => handleCategoryClick(e, category)}>
                        <Component.Shared.TextComponent className='text-submenu'  margin={0} value={category.descricao} color='grey' size={13} align="flex-start" />
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default SideBarComponentHeader;