import { FaBars } from "react-icons/fa";
import Component from "../../../components/components";
import { useContext, useState } from "react";
import Category from "../../../types/category.type";
import { useTheme } from "../../../context/theme.context";
import { CategoryContext } from "../../../context/category.provider";
import { Link } from "react-router-dom";
import './index.css'; // Importando o arquivo CSS


const SideBarMobileHeader: React.FC = ({ }) => {
  const { currentTheme } = useTheme();
  const { categories, selectCategory, selectSubCategory } = useContext(CategoryContext);
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null);

  // Manipula o clique na categoria para dispositivos móveis e desktops
  const handleCategoryClick = (category: Category) => {

    setActiveSubmenu(activeSubmenu === category.id ? null : category.id);
  };

  const handleSubCategoryClick = (category: any, subcategory: any) => {
    selectCategory(category);
    selectSubCategory(subcategory);
    setBlockMenu(true);

  };


  const [blockMenu, setBlockMenu] = useState(true);
  // Função para alternar a exibição do menu hamburguer
  const handleToggleMenu = () => {
    setBlockMenu((prevShowMenu) => !prevShowMenu);
  };


  return (
    <div className={`navigation-headermobile`} style={{ backgroundColor: currentTheme.color.primary }} >
      <div className="hamburger-icon" onClick={handleToggleMenu}>
        <FaBars color={'white'} size={32} />
      </div>
      <div className={`navigation-headermobile  ${blockMenu ? 'block-menu' : ''}`} style={{ backgroundColor: currentTheme.color.primary }} >
        <nav className="navmobile" >
          <ul>
            {categories.map(category => (
              <li key={category.id} className="menu-itemmobile" onClick={(e) => handleCategoryClick(category)}
              >
                {category.descricao}
                {activeSubmenu === category.id && category.subCategorias &&
                  <ul className="submenumobile">
                    {category.subCategorias.map(subcategory => (
                      <li key={subcategory.id}   >
                        <Link
                          to="/produtos"
                          onClick={() => handleSubCategoryClick(category, subcategory)}
                          className="submenu-limobile"
                        >
                          <Component.Shared.TextComponent  margin={0} value={subcategory.descricao} color={'white'} size={13} align="flex-start" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                }
              </li>

            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default SideBarMobileHeader;