import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import React, { createContext, ReactNode, useEffect, useState } from 'react'

import api from '../services/api'
import { ClientListInputProps, LoginInputProps, RegisterInputProps } from '../components/schemas/user.schemas'
import UserProps from '../types/user.type'


interface AuthContextProps {
  isAuthenticated: boolean,
  user: UserProps | null,
  mostrarClientes: boolean,
  representante: number | null,
  error: null | string
  login: (data: LoginInputProps) => Promise<boolean>
  loginById: (data: ClientListInputProps) => Promise<boolean>
  create: (data: RegisterInputProps) => Promise<string>
  logout: () => void
  clearError: () => void
}

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  user: null,
  mostrarClientes: false,
  error: null,
  representante: null,
  create: async () => '',
  login: async () => false,
  loginById: async () => false,
  logout: () => { },
  clearError: () => { }
})

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [mostrarClientes, setMostrarClientes] = useState(false)
  const [representante, setRepres] = useState<null | number>(null)

  const [user, setUser] = useState<UserProps | null>(null)
  const [error, setError] = useState(null)

  const create = async (data: RegisterInputProps): Promise<string> => {
    let errormsg = '';

    await api
      .post('/clientes/cadastrar', data)
      .then(response => {
      })
      .catch(error => {
        const errorMessage = error.response && error.response.data.message ? error.response.data.message : error.response;
        setError(errorMessage);
        console.log(error.response);
        errormsg = errorMessage;
      })

    return errormsg;
  }

  const login = async (data: any): Promise<boolean> => {

    let auth = false;
    await api
      .post('/usuarios/login', data)
      .then(response => {
        const token = response.data.token

        Cookies.set('AccessToken', token, {
          expires: 1,
          secure: true,
          sameSite: 'Lax'
        })
        const decoded = jwtDecode<UserProps>(token)
        setUser(decoded)

        if (decoded.EscolherClientes === "true") {
          setMostrarClientes(true);
        }
        else {
          setMostrarClientes(false);
        }
        if (decoded.CodigoRepresentante) {
          setRepres(decoded.CodigoRepresentante)
        }

        setIsAuthenticated(true)
        auth = true;
      })
      .catch(error => {
        setError(error.response.data)
        auth = false;
      })
    return auth;
  }


  const loginById = async (data: any): Promise<boolean> => {

    let auth = false;
    await api
      .post('/usuarios/loginById?clienteid=' + data.usuario_id)
      .then(response => {
        const token = response.data.token

        Cookies.set('AccessToken', token, {
          expires: 1,
          secure: true,
          sameSite: 'Lax'
        })
        const decoded = jwtDecode<UserProps>(token)
        setUser(decoded)
        console.log(decoded);
        setIsAuthenticated(true)
        if (decoded.EscolherClientes === "true") {
          setMostrarClientes(true);
        }
        else {
          setMostrarClientes(false);
        }
        auth = true;
      })
      .catch(error => {
        setError(error.response.data)
        auth = false;
      })
    return auth;
  }

  const logout = async () => {
    try {
      Cookies.remove('AccessToken')
      setUser(null)
      setIsAuthenticated(false)
    } catch (error) {
      console.error(error)
    }
  }

  const clearError = async () => {
    try {
      setError(null);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const token = Cookies.get('AccessToken')
    if (token) {
      const decoded = jwtDecode<UserProps>(token)
      if (decoded.exp * 1000 < Date.now()) {
        setUser(null)
        setIsAuthenticated(false)
        Cookies.remove('AccessToken')
      }
      else {
        setUser(decoded)
        setIsAuthenticated(true)
      }


    }
  }, [])

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, mostrarClientes, representante, login, loginById, logout, user, create, error, clearError }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider