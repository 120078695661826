// Header.tsx
import React, { useContext, useEffect, useState } from 'react';

import './header.css'; // Importando o arquivo CSS

import Component from "../../components/components"
import { FaLock, FaShoppingCart, FaUser } from 'react-icons/fa';
import { CartContext } from '../../context/cart.provider';
import { Link, useNavigate } from 'react-router-dom';
import { CategoryContext } from '../../context/category.provider';
import api, { BASE_URL } from '../../services/api';
import RepresentadaComponent from '../../components/custom/representada-list/representada.component';
import Representada from '../../types/representada.type';
import { useTheme } from '../../context/theme.context';
import { RepresentadaContext } from '../../context/representada.provider';
import { AuthContext } from '../../context/auth.provider';
import SideBarMobileHeader from './sidebarmobile/sidebarmobileheader.component';
import SideBarComponentHeader from './siderbar/siderbar.component';
// Defina os tipos das props, caso existam props

interface HeaderProps { }

const Header: React.FC<HeaderProps> = () => {

  const { selectedRepresentada, selectRepresentada } = useContext(RepresentadaContext);
  const { currentTheme } = useTheme();

  const { cart, clearCart } = useContext(CartContext);
  const { user } = useContext(AuthContext);
  const { getCategories } = useContext(CategoryContext);
  const [showCartBox, setShowCartBox] = useState(false);
  const [representadas, setRepresentadas] = useState<Representada[]>([]);
  let cartBoxTimer: ReturnType<typeof setTimeout>;
  const [loading, setIsLoading] = useState(false);

  const [showClientList, setShowClientList] = useState(false);
  const toggleClientList = () => {
    setShowClientList(!showClientList);
  };
  const handleMouseEnterCart = () => {
    cartBoxTimer = setTimeout(() => {
      setShowCartBox(true);
    }, 200);
  };

  const handleMouseLeaveCart = () => {
    clearTimeout(cartBoxTimer);
    setShowCartBox(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  useEffect(() => {

   // setIsLoading(true);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    listar();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);

  const listar = async () => {

    await api.post('/Fornecedores/buscarrepresentadas'
    ).then(response => {
      setRepresentadas(response.data)
     // setIsLoading(false);
    }).catch(error => {
     // setIsLoading(false);
    })

  };

  const { setCurrentTheme } = useTheme();
  const selecionarItem = (item: Representada) => {
    //limpar o carrinho 
    clearCart();
    // Dentro do seu componente que usa o contexto
    setCurrentTheme(prevTheme => ({
      ...prevTheme,
      color: {
        ...prevTheme.color,
        primary: item.cor ? item.cor : '#00b7bd'// nova cor primária
      }
    }));

    selectRepresentada(item);
    getCategories(item.id);
    navigate('/home');
  };

  const isMobile = windowWidth <= 880;
  return (
    <header>
        {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}

      {showClientList && <Component.Custom.ClientListComponent onClose={toggleClientList} />}

      <div className="showadminpanel">
        <div className="painel-representada" >
          {representadas.map((object, index) => (
            <>
              <div className="painel-representada" key={index} onClick={() => selecionarItem(object)}>
                <Component.Shared.ImageComponent source={`${BASE_URL}uploads/${object.logo_miniatura}`} width={60} height={20} margin={0} className="logo-representada" />
              </div>
              <hr />
            </>
          ))}
        </div>

        {user?.EscolherClientes && (
          <div className="icon-admin-painel" style={{ cursor: 'pointer' }} onClick={toggleClientList}>
            <span className='span-subtext'>{user?.Cliente || 'Clique aqui para selecionar o cliente'}</span> <br />
          </div>
        )}

        {
          user?.CodigoRepresentante && (
            <div className="icon-admin-painel">
              <Link to="/admin-home" style={{ color: 'white' }}>
                <FaLock color='black' title='Acessar o painel administrativo' size={19} />
              </Link>
            </div>
          )}
      </div>
      <div className={`top-menu`} style={{ backgroundColor: currentTheme.color.secondary }}>
        {/* Logo à esquerda */}
        <div className="image-icon">
          <Link to="/home">
            <Component.Shared.ImageComponent source={selectedRepresentada ? `${BASE_URL}uploads/${selectedRepresentada.logo}` : '/AYRES.webp'} margin={10} className="" />
          </Link>
        </div>
        <div className="search-bar">
          <Component.Custom.SeachBar />
        </div>
        <div className='icones'>
          <Link to="/panel">
            <div
              className="perfil"
            >
              <div className="cart-icon-wrapper">
                <FaUser id="search-icon" color={currentTheme.color.primary} size={32} />
              </div>
              <span className='span-header' style={{ marginLeft: '8px', color: currentTheme.color.primary }}>MEUS PEDIDOS</span>
            </div>
          </Link>
          <div
            className="cart"
            onMouseEnter={handleMouseEnterCart}
            onMouseLeave={handleMouseLeaveCart}
          >
            <div className="cart-icon-wrapper">
              <FaShoppingCart id="search-icon" color={currentTheme.color.primary} size={32} />
              <div className="cart-item-count" style={{ backgroundColor: currentTheme.color.primary }}>{cart.length}</div>
            </div>
            <span className='span-header' style={{ marginLeft: '8px', color: currentTheme.color.primary }}>MEU CARRINHO</span>
            {showCartBox && <Component.Custom.Cart />}
          </div>
        </div>
      </div>

      {selectedRepresentada && (
        isMobile ? (
          <SideBarMobileHeader />
        ) : (
          <SideBarComponentHeader />
        )
      )}

    </header>
  );
};

export default Header;