import { useEffect, useState } from "react";
import PaginationComponent from "../../../pagination/pagination.components";
import api, { BASE_URL } from "../../../../../services/api";
import Component from "../../../../components";
import Representada from "../../../../../types/representada.type";
import noimage from '../../../../../images/noimg.jpg';
import "./index.css";
import ProdutoType from "../../../../../types/productcard.type";
import { Link } from "react-router-dom";


const SaldoEstoque: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [listProducts, setListProducts] = useState<ProdutoType[]>([])
    const [loading, setIsLoading] = useState(false);

    const [representadas, setRepresentadas] = useState<Representada[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const [showModal, setShowModal] = useState(false);
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };



    useEffect(() => {
        listarRepresentada();
        gerarRelatorio(0);
    }, []);

    const gerarRelatorio = async (representadaId: number) => {
        setIsLoading(true); // Inicia o indicador de carregamento

        // Constrói a URL baseada na condição do representadaId
        let url = '/relatorios/saldo_estoque';
        if (representadaId !== 0) {
            url += `?representada=${representadaId}`;
        }

        // Faz a chamada à API usando a URL construída
        await api.get(url)
            .then(response => {
                setListProducts(response.data); // Atualiza os produtos com a resposta da API
                setIsLoading(false); // Finaliza o indicador de carregamento
            })
            .catch(error => {
                console.error("Erro ao gerar relatório:", error); // Loga qualquer erro que ocorrer na requisição
                setIsLoading(false); // Finaliza o indicador de carregamento em caso de erro
            });
    };


    function removeDiacritics(text: string): string {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }


    const listarRepresentada = async () => {

        await api.post('/Fornecedores/buscarrepresentadas'
        ).then(response => {
            setRepresentadas(response.data)
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })

    };
    const [representadaId, setRepresentadaId] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const handleRepresentadaChange = (value: string) => {
        setRepresentadaId(value);
        gerarRelatorio(Number(value));
    };


    const handleImageClick = (value: string) => {
        setShowModal(true);
        setImageUrl(value)
    };

    return (
        <div>
            <div className="divisor-container">
                {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}
                <div>
                </div>

                <div className="divisor1">

                    <input
                        type="text"
                        placeholder="Filtrar por nome do produto"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Component.Shared.SelectComponent onChange={handleRepresentadaChange} margin={0} marginTop={10} align='flex-start' data={representadas} size={16} color="black" placeholder="* Representada" />

                </div>

            </div>
            <div className="grid-container">
                <table>
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Código Barras</th>
                            <th style={{ textAlign: 'left' }}>Nome</th>
                            <th>Saldo</th>
                            <th>Preço</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listProducts && (

                            listProducts

                                .filter(cli => {
                                    // Se searchTerm for vazio, inclua todos os itens.
                                    if (!searchTerm) return true;
                                    return removeDiacritics(cli.nome.toLowerCase()).includes(removeDiacritics(searchTerm.toLowerCase()));
                                })

                                .slice(indexOfFirstProduct, indexOfLastProduct).map((product) => (

                                    <tr key={product.id}>
                                        <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <Component.Shared.ImageProductComponent className="item-image-grid" source={product.imagens.length > 0 ? `${BASE_URL}uploads/${product.imagens[0].imagem}` : noimage} onClick={() => handleImageClick(product.imagens.length > 0 ? `${BASE_URL}uploads/${product.imagens[0].imagem}` : noimage)} />
                                        </td>
                                        <td>{product.barras}</td>
                                        <td style={{ textAlign: 'left', lineHeight: '20px' }}>{product.nome} ({product.id})  </td>
                                        <td>{product.saldo}</td>
                                        <td>R${product.preco.toFixed(2)}</td>
                                    </tr>
                                )))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={listProducts.length}
                setCurrentPage={handlePageChange}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
            {showModal && (
                <Component.Shared.Modal show={showModal} onClose={() => setShowModal(false)} imageSrc={imageUrl} />
            )}
        </div>
    );
}

export default SaldoEstoque;