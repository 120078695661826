import { ReactNode, createContext } from "react";
import { Product } from "../types/product.type";
import api from "../services/api";

interface ProductContextProps {
  getProductsLancamento: (id: string, representada_id: number) => Promise<Product[]>;
  getProductsbyCategoryId: (category_id: number, id: string, representada_id: number) => Promise<Product[]>;
  getProductsbySubCategoryId: (category_id: number, id: string, representada_id: number) => Promise<Product[]>;
  getProductsSearch: (name: string, id: string, representada_id: number) => Promise<Product[]>;
  getProductRegistration: (
    nome: string,
    descricao: string,
    preco: string,
    saldo: string,
    custo: string,
    margemBruta: string,
    marca: string,
    imagens: string[],
    id: string,
  ) => Promise<Product | null>;
  getProductsList: () => Promise<Product[]>;
  getProductsDelete: (productId: number) => Promise<void>;
}

export const ProductContext = createContext<ProductContextProps>({
  getProductsLancamento: async () => { return [] },
  getProductsbyCategoryId: async () => { return [] },  // Corrigindo aqui
  getProductsbySubCategoryId: async () => { return [] },  // Corrigindo aqui
  getProductsSearch: async () => { return [] },  // Corrigindo aqui
  getProductRegistration: async () => null,
  getProductsList: async () => {return []},
  getProductsDelete: async () => { return; }
});

export const ProductProvider = ({ children }: { children: ReactNode }) => {

  const getProductRegistration = async (
    nome: string,
    descricao: string,
    preco: string,
    saldo: string,
    custo: string,
    margemBruta: string,
    marca: string,
    imagens: string[],
    id: string,
  ) => {
    try {
      const precoValor = parseFloat(preco);
      const saldoValor = parseFloat(saldo);
      const custoValor = parseFloat(custo);
      const margemBrutaValor = parseFloat(margemBruta);
      const imagensObj = imagens.map((url, index) => ({
        id: index + 1, 
        url: url,
      }));
  
      const response = await api.post('/Produtos/inserir', {
        nome: nome,
        descricao: descricao,
        preco: precoValor,
        saldo: saldoValor,
        custo: custoValor,
        margemBruta: margemBrutaValor,
        marca: marca,
        imagens: imagensObj, 
        id: id,
      });
  
      if (response.status === 201) {
        console.log('Produto cadastrado com sucesso');
        return response.data as Product;
      } else {
        console.error('Erro ao cadastrar o produto');
        return null;
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
      return null;
    }
  };

  const getProductsList = async () =>{
    try{
      const response = await api.get('/Produtos/listar');
      if (response.status === 200){
        return response.data as Product [];
      } else{
        console.error('Erro ao listar os produtos.');
        return [];
      } 
    } catch (error){
      console.error('Erro ao enviar a solicitação:', error);
      return [];
    }
  }

  const getProductsDelete = async (id: number) => {
    try {
      const response = await api.delete(`/Produtos/excluir`);
      if (response.status === 204) {
        console.log('Produto deletado com sucesso.');
      } else {
        console.error('Erro ao deletar o produto.');
      }
    } catch (error) {
      console.error('Erro ao enviar a solicitação:', error);
    }
  }

  const getProductsLancamento = async (id: string, representada_id: number) => { 
    try {
      const response = await api.post('/produtos/listarlancamentos',
      {
        id: id,
        representada_id: representada_id
      });

      return response.data;
    } catch (error) {
      console.error('error');
      return [];
    }
  }

  const getProductsbyCategoryId = async (category_id: number, id: string, representada_id: number) => { 
    try {
      const response = await api.post('/produtos/getprodutosbycategoryid',
      {
        category_id: category_id,
        id: id,
        representada_id: representada_id
      });

      return response.data;
    } catch (error) {
      console.error('error');
      return [];
    }
  }

  const getProductsbySubCategoryId = async (subcategory_id: number, id: string, representada_id: number) => { 
    try {
      const response = await api.post('/produtos/getprodutosbysubcategoryid',
      {
        subcategory_id: subcategory_id,
        id: id,
        representada_id: representada_id
      });

      return response.data;
    } catch (error) {
      console.error('error');
      return [];
    }
  }

  const getProductsSearch = async (name: string, id:string, representada_id: number) => { 
    try {
      const response = await api.post('/produtos/pesquisar', {
        value: name,
        id: id,
        representada_id: representada_id
      });

      return response.data;
    } catch (error) {
      console.error('error');
      return [];
    }
  }
  return (
    <ProductContext.Provider value={{  getProductsLancamento, getProductsList, getProductRegistration, getProductsDelete, getProductsbyCategoryId , getProductsbySubCategoryId, getProductsSearch}}>
      {children}
    </ProductContext.Provider>
  );
};