import { useEffect, useState } from "react";
import PaginationComponent from "../../../pagination/pagination.components";
import api from "../../../../../services/api";
import Component from "../../../../components";
import Client from "../../../../../types/client.type";
import { RankingProdutosInputProps, clientlistSchema } from "../../../../schemas/user.schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from 'date-fns';
import { FaSearch } from "react-icons/fa";
import Swal from "sweetalert2";


type Type =
    {
        id: number;
        nome: string;
        totalVendido: number;
    }


const RankingProdutos: React.FC = () => {
    const [list, setList] = useState<Type[]>([])

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setIsLoading] = useState(false);

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const [clientes, setClientes] = useState<Client[]>([]);

    const {
        formState: { errors },
    } = useForm<RankingProdutosInputProps>({ resolver: zodResolver(clientlistSchema) })

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    useEffect(() => {

        listarClientes();
    }, []);

    const listarClientes = async () => {
        await api.get('/relatorios/clientes'
        ).then(response => {
            setClientes(response.data)
        }).catch(error => {
        })
    };

    const [perA, setPerA] = useState<Date | null>(null);

    const handleDateChangeA = (date: Date | null) => {
        setPerA(date);
    };
    const [clienteid, setClientId] = useState('');
    const handleClientChange = async (value: string) => {
        setClientId(value);

    };

    const [perB, setPerB] = useState<Date | null>(null);

    const handleDateChangeB = async (date: Date | null) => {
        setPerB(date);
    };



    const handleGerarRelatorio = async () => {

        
        if (!perA || !perB) {
            Swal.fire({
                title: 'Mensagem',
                text: "Informe o período para gerar o relatório.",
                icon: 'info',
            });
        }

        setIsLoading(true);
        setList([]);
        const formattedPerA = perA ? format(perA, 'dd/MM/yyyy') : '';
        const formattedPerB = perB ? format(perB, 'dd/MM/yyyy') : '';

        if (clienteid) {

            await api.get(`relatorios/ranking_produtos_cliente?perA=${formattedPerA}&perB=${formattedPerB}&clienteid=${clienteid}`
            ).then(response => {
                setList(response.data)

                if (response.data.length === 0) {
                    Swal.fire({
                        title: 'Mensagem',
                        text: "Não há dados disponíveis para exibir no relatório.",
                        icon: 'info',
                    });
                }
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            })
        }
        else {


            await api.get(`relatorios/ranking_produtos?perA=${formattedPerA}&perB=${formattedPerB}`
            ).then(response => {
                setList(response.data)

                if (response.data.length === 0) {
                    Swal.fire({
                        title: 'Mensagem',
                        text: "Não há dados disponíveis para exibir no relatório.",
                        icon: 'info',
                    });
                }
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            })
        }

    };

    return (
        <div>
            <Component.Shared.TextComponent marginTop={0} value="Período" size={18} color='black' margin={0} align='center' />

            <div className="divisor-container" >
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Data Inicial" onDateChange={handleDateChangeA} />
                </div>
                <div className="divisor1">
                    <Component.Shared.DatePicker texto="Data Final" onDateChange={handleDateChangeB} />
                </div>
            </div>
            <div>
                <Component.Shared.TextComponent marginTop={0} value="Filtrar por cliente" size={18} color='black' margin={0} align='center' />
                <div>
                    <Component.Shared.SelectComponent filter onChange={handleClientChange} margin={0} marginTop={10} align='flex-start' data={clientes} size={16} color="black" placeholder="* Cliente" />
                    <div className="btn btn-primary button" onClick={() => handleGerarRelatorio()}>
                        <FaSearch size={18} />
                        <span>Gerar relatório</span>
                    </div>
                </div>

            </div>

            <div className="grid-container">
                {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>Ranking</th>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list && (
                            list.slice(indexOfFirstProduct, indexOfLastProduct).map((product, index) => (
                                <tr key={product.id}>
                                    <td style={{color: '#ee4d2d', fontWeight: 'bold'}}>{indexOfFirstProduct + index + 1}</td>
                                    <td>{product.id}</td>
                                    <td>{product.nome}</td>
                                    <td>R$ {product.totalVendido.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>

                                </tr>
                            )))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                currentPage={currentPage}
                totalItems={list.length}
                setCurrentPage={handlePageChange}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
}

export default RankingProdutos;