

import CadastroCategoria from "./custom/admin/cadCategoriaSubcategoria/cadCat"
import CadastroEnderecoCliente from "./custom/admin/cadCliente/cadCliente"
import CadastroDocumento from "./custom/admin/cadDocto/cadDocto"
import CadastroCondPagto from "./custom/admin/cadPagto/cadPagto"
import CadastroProduto from "./custom/admin/cadProduto/cadastro-produto"
import CadastroSubcategoria from "./custom/admin/cadSub/cadSub"
import CadastroMarca from "./custom/admin/cadMarca/cadMarca"
import ContentAdminComponent from "./custom/admin/content/content.admin.component"
import OrderControl from "./custom/admin/clientes/cliente.component"
import FooterAdminComponent from "./custom/admin/footer/footer.admin.component"
import HeaderAdminComponent from "./custom/admin/header/header.admin.component"
import HomeContent from "./custom/admin/home-content/home-content.component"
import SideBarMobile from "./custom/admin/sidebarmobile/sidebarmobile.component"
import SiderBarComponent from "./custom/admin/siderbar/siderbar.component"

import BannerHomeComponent from "./custom/carrousel/banner-home.component"
import BannerCarrouselComponent from "./custom/carrousel/carrousel.components"
import CartComponent from "./custom/cart/cart.component"
import ClientListComponent from "./custom/client-list/cliente-list.component"
import  NewEnderecoEntregaComponent  from "./custom/new-endereco-entrega/new-endereco-entrega.component"
import OrderDetailComponent from "./custom/order-detail/order.detail.components"
import PaginationComponent from "./custom/pagination/pagination.components"
import PanelComponent from "./custom/panel/panel.components"
import ProductCardComponent from "./custom/product-card/product.card.components"
import ProductDetailComponent from "./custom/product-detail/product.detail.components"
import ProductListComponent from "./custom/product-list/product.list.components"
import ScrollComponent from "./custom/scroll-products/scroll.products.components"
import SearchBarComponent from "./custom/searchBar/searchBar.component"
import SubCategoriesList from "./custom/subcategories-list/subcategories.list.components"
import VariationCardThumbComponent from "./custom/variation-card-thumb/variation.card.thumb.components"
import VariationCardComponent from "./custom/variation-card/variation.card.components"
import ButtonComponent from "./shared/button.shared.component"
import ImageProductComponent from "./shared/image-product.shared.component"

import ImageComponent from "./shared/image.shared.component"
import LoaderComponent from "./shared/loader.shared.component"
import SelectComponent from "./shared/select.shared.component"
import TextInputComponent from "./shared/text.input.component"
import TextComponent from "./shared/text.shared.component"
import RepresentadaComponent from "./custom/representada-list/representada.component"
import SaldoEstoque from "./custom/admin/relatorios/saldo-estoque/saldoestoque.component"
import ClientesSemCompra from "./custom/admin/relatorios/clientes-sem-compra/clientessemcompra.component"
import RankingClientes from "./custom/admin/relatorios/ranking-clientes/rankingclientes.component"
import GeralVendas from "./custom/admin/relatorios/geral-vendas/geralvendas.component"
import HistoricoPedidos from "./custom/admin/historico-pedidos/historicopedidos.component"
import RankingProdutos from "./custom/admin/relatorios/ranking-produtos/rankingprodutos.component"
import Clientes from "./custom/admin/clientes/cliente.component"
import Inadimplencia from "./custom/admin/relatorios/inadimplencia/inadimplencia.component"
import DetalharPedido from "./custom/admin/relatorios/detalhar-pedido/detalharpedido.component"
import DatePickerComponent from "./shared/datepicker.component"
import AddClientes from "./custom/admin/add-cliente/addcliente.component"
import GraficoPizzaComponent from "./shared/grafico-barra.shared.component"
import AddPedidos from "./custom/admin/add-pedido/addpedido.component"
import ListaClienteCard from "./shared/listclientecard.shared.component"
import ClienteCard from "./shared/clientecard.shared.component"
import ProductCard from "./shared/productcard.shared.component"
import ListaProductCard from "./shared/listproductcard.shared.component"
import CadastroForn from "./custom/admin/cadForn/cadForn"
import MonthPickerComponent from "./shared/monthpicker.component"
import PedidoCard from "./shared/pedidocard.shared.component"
import ListaPedidoCard from "./shared/listpedidocard.shared.component"
import GridDetalhesRepres from "./custom/admin/indicadores/detalhesrepres/detalhesRepres.component"
import GridDetalhesCarteira from "./custom/admin/indicadores/detalhescarteira/detalhesCarteira"
import GraficoPositivacao from "./shared/grafico-pizza-positiv.shared.component"
import GraficoCurvaABC from "./shared/grafico-pizza-curvaabc.shared.component"
import GraficoBarraComponent from "./shared/grafico-barra.shared.component"
import GridDetalhesPositivacao from "./custom/admin/indicadores/detalhesPositivacao/detalhesPositivacao"
import GridDetalhesCurvaABC from "./custom/admin/indicadores/detalhescurvaABC/detalhesCurvaABC"
import AlterarPedido from "./custom/admin/alterar-pedido/alterarpedido.component"
import ModalComponent from "./shared/modal.shared.component"


const Component = {
    Custom: {
        SeachBar: SearchBarComponent,
        BannerCarrousel: BannerCarrouselComponent,
        ProductDetail: ProductDetailComponent,
        ProductList: ProductListComponent,
        Cart: CartComponent,
        ProductCard: ProductCardComponent,
        Panel: PanelComponent,
        Scroll: ScrollComponent,
        Pagination: PaginationComponent,
        OrderDetail: OrderDetailComponent,
        NewEnderecoEntregaComponent: NewEnderecoEntregaComponent,
        BannerHomeComponent :BannerHomeComponent,
        ClientListComponent: ClientListComponent,
        VariationCard: VariationCardComponent,
        VariationCardThumb: VariationCardThumbComponent,
        SubCategoriesList: SubCategoriesList,
        SideBarComponent: SiderBarComponent,
        SideBarMobile: SideBarMobile,
        HeaderAdminComponent: HeaderAdminComponent,
        FooterAdminComponent: FooterAdminComponent,
        ContentAdminComponent: ContentAdminComponent,
        CadastroDocumento: CadastroDocumento,
        CadastroCategoria: CadastroCategoria,
        CadastroSubcategoria: CadastroSubcategoria,
        CadastroEnderecoCliente: CadastroEnderecoCliente,
        CadastroCondPagto: CadastroCondPagto,
        CadastroProduto: CadastroProduto,
        CadastroForn: CadastroForn,
        Clientes: Clientes,
        CadastroMarca: CadastroMarca, 
        SaldoEstoque: SaldoEstoque,
        ClientesSemCompra: ClientesSemCompra,
        RankingClientes: RankingClientes,
        RankingProdutos : RankingProdutos,
        GeralVendas : GeralVendas,
        HistoricoPedidos : HistoricoPedidos,
        Inadimplencia: Inadimplencia,
        DetalharPedido: DetalharPedido,
        HomeContent: HomeContent,
        RepresentadaComponent: RepresentadaComponent,
        AddClientes : AddClientes,
        AddPedidos : AddPedidos,
        AlterarPedido: AlterarPedido,
        GridDetalhesRepres: GridDetalhesRepres,
        GridDetalhesCarteira: GridDetalhesCarteira,
        GridDetalhesPositivacao: GridDetalhesPositivacao,
        GridDetalhesCurvaABC: GridDetalhesCurvaABC

    },
    Layout: {

    },
    Shared: {
        LoaderComponent: LoaderComponent,
        TextComponent: TextComponent,
        TextInputComponent: TextInputComponent,
        ImageComponent: ImageComponent,
        SelectComponent: SelectComponent,
        ButtonComponent: ButtonComponent,
        ImageProductComponent: ImageProductComponent,
        DatePicker: DatePickerComponent,
        MonthPicker: MonthPickerComponent,
        GraficoPizza : GraficoPizzaComponent,
        ClienteCard: ClienteCard,
        ListaClienteCard: ListaClienteCard,
        ProductCard : ProductCard,
        ListProductCard: ListaProductCard,
        PedidoCard : PedidoCard,
        ListaPedidoCard: ListaPedidoCard,
        GraficoPositivacao: GraficoPositivacao,
        GraficoCurvaABC: GraficoCurvaABC,
        GraficoBarra: GraficoBarraComponent,
        Modal: ModalComponent

        
    }
}

export default Component