import React, { useContext, useEffect, useState } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Link, useLocation } from 'react-router-dom';
import Component from '../../components/components';
import { BASE_URL } from '../../services/api';
import Product from '../../types/product.type';
import { ProductContext } from '../../context/product.provider';
import noimage from '../../images/noimg.jpg';
import './index.css';
import { CartContext } from '../../context/cart.provider';
import { AuthContext } from '../../context/auth.provider';
import { useTheme } from '../../context/theme.context';
import { RepresentadaContext } from '../../context/representada.provider';
const SearchResults: React.FC = () => {
  const { getProductsSearch } = useContext(ProductContext);
  const { cart, addProduct } = useContext(CartContext);
  const [message, setMessage] = useState<string | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(16);
  const { user } = useContext(AuthContext);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const { currentTheme } = useTheme();
  const { selectedRepresentada } = useContext(RepresentadaContext);
  const location = useLocation();
  const search = location.state ? location.state : "";

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (user && user.sub && search.length > 0 && selectedRepresentada) {
        const fetchedProducts = await getProductsSearch(search, user.sub, selectedRepresentada.id);
        setProducts(fetchedProducts);
        setCurrentPage(1);
      }
      setIsLoading(false);
    })();
  }, [search, user, selectedRepresentada]);

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [quantities, setQuantities] = useState<{ [key: number]: number }>({});
  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);

  };



  const handleBlur = (productId: number) => {
    setQuantities(prevQuantities => {
      if (!prevQuantities[productId]) {
        return {
          ...prevQuantities,
          [productId]: 1,
        };
      }
      return prevQuantities;
    });
  };

  const handleQuantityChange = (productId: number, quantity: number) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const handleBuyClick = (product: Product) => {

    const productQuantity = quantities[product.id] || 1;

    // Criar uma cópia do produto e adicionar a propriedade quantity
    const productWithQuantity = {
      ...product,
      qtde: productQuantity
    };

    console.log(productQuantity);

    if (cart.some(item => item.id === product.id)) {
      setMessage('Produtos já estão no carrinho.');
    } else {

      if (product.saldo < productQuantity) {
        handleQuantityChange(product.id, Number(product.saldo))
        setMessage('Saldo insuficiente para esse produto.');
      }
      else {
        addProduct(productWithQuantity);
        setMessage('Produtos adicionados com sucesso!');
      }
    }

    setQuantities(prevQuantities => {
      if (!prevQuantities[product.id]) {
        return {
          ...prevQuantities,
          [product.id]: 1,
        };
      }
      return prevQuantities;
    });
    setTimeout(() => setMessage(null), 1500);
  };


  return (
    <div>
      <Header />
      <div>
        {message && <div className="success-message-search">{message}</div>}
        <div className="container container-product-grid-full">
          <div className='list-products columns-teste'>
            <div className="page-header">
              <h1 >Resultados para "{search}"</h1>
            </div>
            {loading ? (
              <div className="product-container">
                <Component.Shared.LoaderComponent />
              </div>
            ) : products.length === 0 ? (
              <div className="no-products-message">
                <Component.Shared.TextComponent margin={0} value={'Verifique se não há erro de digitação.'} color={'#747474'} size={16} align="center" />
                <Component.Shared.TextComponent margin={0} value={'Tente utilizar uma única palavra.'} color={'#747474'} size={16} align="center" />
                <Component.Shared.TextComponent margin={0} value={'Tente buscar por termos menos específicos e posteriormente use os filtros da busca.'} color={'#747474'} size={16} align="center" />
                <Component.Shared.TextComponent margin={0} value={'Procure utilizar sinônimos ao termo desejado.'} color={'#747474'} size={16} align="center" />
              </div>
            ) : (
              products.slice(startIndex, endIndex).map((product, index) => (
                <article className="product" key={index}>
                  <Link to={`/produtos/detalhes/${product.id}`}
                    state={{ value: product }}
                    style={{ color: currentTheme.color.fontColorsTitle }}>
                    <div className="teste2-click" onClick={() => handleProductClick(product)}>

                      <img className="item-image-search" src={product.imagens.length > 0 ? `${BASE_URL}uploads/${product.imagens[0].imagem}` : noimage} />
                      <div className="item-name-product">
                        <Component.Shared.TextComponent margin={0} value={product.nome} fontWeight={400} color={product.saldo > 0 ? "#444444" : "#C9C9C9"} size={16} align="center" />
                        <Component.Shared.TextComponent margin={5} value={'R$ ' + product.preco.toFixed(2)} color={product.saldo > 0 ? "#646464" : "#696969"} size={18} align="center" />
                      </div>
                    </div>
                  </Link>
                  {product.saldo > 0 ? (
                    <div className="input-button-container">
                      <input
                        type="number"
                        value={quantities[product.id] || ''}
                        className='input-cart teste2'
                        onBlur={() => handleBlur(product.id)}
                        onChange={e => handleQuantityChange(product.id, Number(e.target.value))}
                      />

                      {
                        product.variacao === true ? (

                          <Link to={`/produtos/detalhes/${product.id}`}
                            state={{ value: product }}
                            style={{ color: currentTheme.color.fontColorsTitle }}>
                            <button
                              id={`buy-button-${index}`}
                              type="submit"
                              className="btn btn-success teste2"
                            >
                              <i className="fa fa-cart-arrow-down"></i> ESCOLHER
                            </button>
                          </Link>

                        ) :

                          (
                            <button
                              id={`buy-button-${index}`}
                              type="submit"
                              className="btn btn-success teste2"
                              onClick={() => handleBuyClick(product)}
                            >

                              <i className="fa fa-cart-arrow-down"></i> COMPRAR
                            </button>
                          )
                      }


                    </div>
                  ) :
                    <h4>INDISPONÍVEL</h4>}
                </article>
              )))}

            <Component.Custom.Pagination totalItems={products.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage} />

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchResults;